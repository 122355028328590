<template>
  <v-sheet
    height="100%"
    tiles
  >
    <div
      class="black--text mx-auto d-flex fill-height align-start justify-center"
    >
      <div
        class="black--text text-center mt-12"
      >
        <span
          :class="[$vuetify.breakpoint.smAndDown ? 'display-2': 'display-3']"
          class="font-weight-black"
        >
          {{$t('contactFormComplte.text1')}}
        </span>

        <br>

        <span
          class="font-weight-light"
          :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
        >
          {{$t('contactFormComplte.text2')}}
        </span>

        <br>

        <v-btn
          class="white--text align-self-end mt-3"
          color="purple"
          @click="goToMainPage"
        >
          {{$t('contactFormComplte.buttons.register')}}
        </v-btn>
      </div>

    </div>
  </v-sheet>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    goToMainPage () {
      window.location.assign('/')
    }
  }
}
</script>
