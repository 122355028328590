<template>
  <v-content>
    <FormContactComplete/>
  </v-content>
</template>

<script>

import FormContactComplete from '@/views/components/FormContactComplete.vue'
export default {
  data () {
    return {
    }
  },
  methods: {
    setDocumentTitle () {
      document.title = this.$t('base.documentTitle')
    }
  },
  components: {
    FormContactComplete
  },
  watch: {
    '$root.$i18n.locale' () { this.setDocumentTitle() }
  },
  created () { this.setDocumentTitle() }
}
</script>
